<template>
    <div>
        <st-filters-pagination
            :header="$t('CONTROL.LIST.TITLE')"
            :total="total"
            stateModule="control/list"
            :filters="filters"
            @change="doFilter"
            ref="filters"
        >
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ControlModel } from '@/modules/control/control-model';

    const { fields } = ControlModel;

    const filterSchema = new FilterSchema([
        fields.created_by,
        fields.status,
        fields.created_date,
        fields.control_id,
        fields.complain_ref,
    ]);

    export default {
        name: 'ControlListFilter',
        computed: {
            ...mapGetters({
                total: 'control/list/total',
            }),
            filters() {
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'control/list/doFetch',
            }),
            doFilter(params) {
                this.doFetch(params);
            },
        },
    };
</script>
