<template>
    <div>
        <Loader  v-if="loading['admin/getInstitutions']"/>
        <form class="form"
                novalidate="novalidate"
                id="st_modal_select_organisation_form"
        >
            <div class="row pb-6">
                <div class="col">
                    <div class="form-group">
                        <label class="mb-4">
                            {{$t('INSTITUTION.FIELDS.ORGANISATION')}}
                        </label>
                        <b-form-select
                            v-model="model.organisation"
                            :options="organisations"
                            ref="organisation"
                            name="organisation"
                            :disabled="!!organisation.id"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'SelectOrganisationForm',
    props: {
        organisation: {
            type: Object,
            default: () => ({ }),
        },
    },
    data() {
        return {
            model: {
                organisation: null
            },
            organisations: []
        };
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            organisationsList: 'administrator/institutions',
            selectedOrg: 'administrator/institution',
        }),
    },
    created() {
        this.getInstitutions({params: { limit: 100 }})
        .then(()=>{
            this.organisations = this.organisationsList.map((org) => ({
                text: org.name,
                value: org.id,
                org
            }))
            if(this.organisation.id) {
                this.model.organisation = this.organisation.id;
                return;
            }
            // For root menu options, autoselect the current selected organisation
            if (this.selectedOrg) {
                this.model.organisation = this.selectedOrg.id;
            }
        })
    },
    mounted() {
        const form = KTUtil.getById('st_modal_select_organisation_form');
        this.fv = formValidation(form, {
            fields: {
                organisation: {
                    validators: {
                        notEmpty: {
                            message: this.$t('VALIDATION.REQUIRED_FIELD'),
                        }
                    }
                }
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        });
    },
    methods: {
        ...mapActions({
            loginDelegatedOrg: 'auth/loginDelegatedOrg',
            getInstitution: 'administrator/getInstitution',
            getInstitutions: 'administrator/getInstitutions',
        }),
        hide() {
            this.$emit('cancelForm');
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const response = await this.loginDelegatedOrg({organisation_id: this.model.organisation});
                await this.getInstitution(response);
                this.$emit('organisationSelected');
            }
        }
    },
};
</script>
