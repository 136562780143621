<template>
    <div>
        <st-modal
            id="st-control-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            size="md"
            hideable
            ref="st-control-modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{headerText}}</p>
            </template>
            <template #body>
                <control-modal-form
                    @cancelForm="hide"
                    @submitForm="submit"
                    :selectedControl="selectedControl"
                    :editMode="editMode"
                >
                </control-modal-form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ControlModalForm from './ControlModalForm.vue';

export default {
    name: 'ControlModal',
    components: {
        ControlModalForm
    },
    props: {
        selectedControl: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headerText() {
            return this.editMode ?
                this.$t('CONTROL.FORM.EDIT_TITLE') :
                this.$t('CONTROL.FORM.ADD_TITLE');
        },
    },
    methods: {
        show() {
            this.$refs['st-control-modal'].show();
        },
        hide() {
            this.$refs['st-control-modal'].hide();
        },
        submit() {
            this.$emit('updateControl');
            this.hide();
        },
    },
}
</script>
