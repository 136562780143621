import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`CONTROL.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`COMPLAIN.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`CONTROL.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'id'),
    created_date: new DateField(
        'created_date',
        label('created_date'),
    ),
    control_id: new StringField(
        'control_id',
        label('control_id'),
        {required: true},
        {
            placeholder: placeholder('control_id'),
            filter_label: label('control_id'),
            primary: false,
        },
    ),
    complain_ref: new StringField(
        'complain_ref',
        label('complain_ref'),
        {required: true},
        {
            placeholder: placeholder('complain_ref'),
            filter_label: label('complain_ref'),
            primary: false,
        },
    ),
    created_by: new StringField(
        'created_by',
        label('created_by'),
        {},
        { placeholder: placeholder('created_by')},
    ),
    status: new EnumField(
        'status',
        label('status'),
        [
            {
                value: 'new',
                name: 'new',
                text: enumLabel('status', 'new'),
                type: 'primary',
            },
            {
                value: 'pending',
                name: 'pending',
                text: enumLabel('status', 'pending'),
                type: 'warning',
            },
            {
                value: 'resolved',
                name: 'resolved',
                text: enumLabel('status', 'resolved'),
                type: 'success',
            },
            {
                value: 'dismissed',
                name: 'dismissed',
                text: enumLabel('status', 'dismissed'),
                type: 'danger',
            },
        ],
        {},
        { placeholder: placeholder('status')},
    ),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('territorial_administrative_unit_id') },
    ),
};

export class ControlModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
