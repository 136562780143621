<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { ControlModel } from '@/modules/control/control-model';

    const { fields } = ControlModel;

    export default {
        name: 'ControlListTable',
        data() {
            return {
                presenter: ControlModel.presenter,
                fields: [
                    fields.created_date,
                    fields.control_id,
                    fields.complain_ref,
                    fields.created_by,
                    fields.status.extendField({ labelType: true }),
                ],
                actions: [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'control/list/rows',
            }),
        },
        methods: {
            onEdit(data) {
                this.$emit('editControl', data);
            },
        },
    };
</script>
