<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st-control-form"
        >
            <div class="form-group">
                <label>
                   {{$t('CONTROL.LABEL.CONTROL_ID')}} *
                </label>
                <st-input-text
                    v-model="control[fields.control_id.name]"
                    :name="fields.control_id.name"
                />
            </div>
            <div class="form-group">
                <label>
                    {{ fields.complain_ref.label }} *
                </label>
                <st-input-text
                    v-model="control[fields.complain_ref.name]"
                    :name="fields.complain_ref.name"
                />
            </div>
            <div class="form-group">
                <label>
                    {{ fields.created_by.label }} *
                </label>
                <st-input-text
                    v-model="control.created_by"
                    :name="fields.created_by"
                />
            </div>
            <div class="d-flex justify-content-between">
                <st-button
                    variant="link"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="cancel"
                > {{ $t('GENERAL.BUTTON.CANCEL') }}
                </st-button>
                <st-button
                    variant="primary"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="submit"
                >
                    <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                </st-button>
            </div>
        </form>
    </div>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { ControlModel } from '@/modules/control/control-model';

const { fields } = ControlModel;

const formSchema = new FormSchema([
    fields.control_id,
    fields.created_by,
    fields.complain_ref,
])

export default {
    name: 'ControlForm',
    props: {
        selectedControl: {
            type: Object,
            required: true,
            default: () => ({ }),
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: formSchema.initialValues(this.selectedControl),
            fields,
            rules: formSchema.rules(),
            control: {}
        }
    },
    created () {
        this.control = this.editMode ? formSchema.initialValues(this.selectedControl) : formSchema.initialValues({});
    },
    mounted() {
        this.fv = createFormValidation('st-control-form', this.rules);
    },
    methods: {
        async submit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const control = formSchema.cast(this.control);
                if (this.editMode) {
                    control.id = this.control.id;
                    // TODO: Update control after BE is done
                } else {
                    // TODO: Add control after BE is done
                }
                this.$emit('submitForm');
            }
        },
        cancel() {
            this.$emit('cancelForm');
        },
    },
}
</script>
